<template>
  <b-container>
    <b-card>
      <b-table
        :fields="[
          { key: 'user_name', label: 'Usuário' },
          { key: 'document', label: 'Documento' },
        ]"
        :items="data"
        hover
        :per-page="8"
        :current-page="page"
        class="text-center"
        @row-clicked="handleTableRowClicked"
      />

      <b-pagination
        v-model="page"
        :total-rows="data.length"
        :per-page="8"
        align="center"
        size="sm"
        class="my-2"
      />
    </b-card>

    <b-modal
      ref="modal-update"
      hide-footer
    >
      <b-form v-if="selectedUserDocument">
        <p>{{ selectedUserDocument.document }}</p>

        <b-form-group
          label="Status"
          label-for="status"
        >

          <b-form-select
            v-model="selectedUserDocument.status"
            :options="[{
              value: null, text: 'Selecione um status',

            }, { value: true, text: 'Aprovado',
            }, { value: false, text: 'Recusado'}]"
          />
        </b-form-group>

        <b-form-group
          label="Opinião"
          label-for="opinion"
        >
          <b-form-input
            v-model="selectedUserDocument.opinion"
            placeholder="Opinião"
          />
        </b-form-group>

        <b-button
          variant="primary"
          :disabled="!selectedUserDocument.opinion || selectedUserDocument.status === null"
          @click="giveOpinion"
        >
          Salvar
        </b-button>
      </b-form>
    </b-modal>
  </b-container>
</template>

<script>
import {
    BContainer, BTable, BCard, BPagination, BModal, BForm, BFormGroup, BFormSelect, BFormInput, BButton,
} from 'bootstrap-vue';

import AdminIrisService from '@/service/online_registration/online-registration';

export default {
    components: {
        BContainer, BTable, BCard, BPagination, BModal, BForm, BFormGroup, BFormSelect, BFormInput, BButton,
    },
    data() {
        return {
            data: [],
            page: 1,
            selectedUserDocument: null,
        };
    },
    async mounted() {
        await this.loadUserDocuments();
    },

    methods: {
        async handleTableRowClicked(documentData) {
            this.callLoading(true);
            await this.downloadDocument(documentData.user_document_id);
            this.selectedUserDocument = documentData;
            this.$refs['modal-update'].show();
            this.callLoading(false);
        },

        async loadUserDocuments() {
            const { data: { data } } = await AdminIrisService.userDocumentInReviewStatus();
            this.data = data;
        },

        async giveOpinion() {
            const isConfirmed = await this.confirmAnAction('Deseja realmente realizar a análise desse documento?');
            if (!isConfirmed) return;
            this.callLoading(true);
            const { status } = await AdminIrisService.giveOpinion({ id: this.selectedUserDocument.user_document_id, status: this.selectedUserDocument.status, opinion: this.selectedUserDocument.opinion });
            if (status === 204) {
                this.selectedUserDocument = null;
                this.$refs['modal-update'].hide();
                await this.loadUserDocuments();
                this.modalSuccess('Arquivo analisado com sucesso');
            } else {
                this.modalError('Erro ao analisar o arquivo');
            }

            this.callLoading(false);
        },

        async downloadDocument(userDocumentId) {
            const response = await AdminIrisService.downloadUserDocument(userDocumentId);

            const fileURL = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
            const fileLink = document.createElement('a');

            fileLink.href = fileURL;
            fileLink.setAttribute('download', 'Relatório de Comissão.pdf');
            document.body.appendChild(fileLink);

            fileLink.click();
            document.body.removeChild(fileLink);
        },
    },
};
</script>
